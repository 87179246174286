import './catalog.element.scss';

import { queryParams } from '../../utils';

import { generateCatalog, retrieveCatalog } from './catalog';

export class CatalogElement extends HTMLElement {
  private _supplierName: string;

  async connectedCallback() {
    const catalogId = queryParams['catalogId'];
    const catalogData = catalogId && (await retrieveCatalog(catalogId));
    this._supplierName = catalogData?.Name || 'Not Found';

    this.innerHTML = `
        <div class="catalog-viewer">
          <!-- navbar -->
          <div class="navbar">
            <div class="navbar__container">
              <img
                class="navbar__logo"
                src="/assets/images/esp_logo_colored.svg"
                loading="lazy"
              />
              <span class="navbar__brand-title">${this._supplierName}</span>
            </div>
          </div>
          <!-- /navbar -->
          <div id="ecatalogViewer"></div>
        </div>
      `;

    catalogData && generateCatalog(encodeURI(catalogData?.Asset));
  }
}
customElements.define('supplier-catalog-viewer', CatalogElement);
