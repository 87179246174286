import { environment } from '../../../environments/environment';

declare global {
  interface Window {
    s7viewers: any;
  }
}

const _asiCatalogParams = {
  containerId: 'ecatalogViewer',
  params: {
    initialframe: 0, // subtract 1 to spread to sync to 0 index of scene7
    portraitFrames: 'split',
    serverurl: 'https://asi.scene7.com/is/image/',
    contenturl: 'https://asi.scene7.com/skins/',
    'PageView.frametransition': 'slide', // none|fade|slide|turn|auto[,duration]
    config: 'ASI/ASI_Catalog_Basic_NoDivider',
  },
} as const;

export function generateCatalog(asset: string) {
  if (!asset) {
    throw new Error('Asset is required to generate the catalog');
  }

  // eslint-disable-next-line no-restricted-globals
  new window.s7viewers.eCatalogViewer({
    ..._asiCatalogParams,
    params: {
      ..._asiCatalogParams.params,
      asset,
    },
  }).init();
}

export function retrieveCatalog(catalogId: number): Promise<any> {
  return fetch(`${environment.catalogsEndpoint}/${catalogId}.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch(() => {
      return {
        Name: 'An error occurred',
      };
    });
}
