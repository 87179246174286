import './elements/catalog';
import './app.element.scss';

export class AppElement extends HTMLElement {
  async connectedCallback() {
    this.innerHTML = `
      <supplier-catalog-viewer></supplier-catalog-viewer>
      `;
  }
}
customElements.define('supplier-catalog-root', AppElement);
